<template>
  <path id="Tracé_579" data-name="Tracé 579" d="M49.624,61.5V54.625H42.749a2.5,2.5,0,1,1,0-5h6.875V42.751a2.5,2.5,0,0,1,5,0v6.875H61.5a2.5,2.5,0,1,1,0,5H54.625V61.5a2.5,2.5,0,0,1-5,0ZM7.585,64A7.594,7.594,0,0,1,0,56.431a7.7,7.7,0,0,1,.162-1.59A32.508,32.508,0,0,1,21.83,30.614,17,17,0,1,1,42.185,30.6a33.023,33.023,0,0,1,3.763,1.51A2.5,2.5,0,1,1,43.8,36.629a27.2,27.2,0,0,0-11.132-2.641C32.447,34,32.224,34,32,34s-.441-.005-.663-.013A27.655,27.655,0,0,0,6.1,52.171a27.171,27.171,0,0,0-1.043,3.687A2.664,2.664,0,0,0,5,56.407a2.6,2.6,0,0,0,.585,1.642,2.538,2.538,0,0,0,2,.95H38.373a2.5,2.5,0,0,1,0,5ZM32.611,28.986A12,12,0,1,0,20,17h0a12.017,12.017,0,0,0,11.4,11.986c.2,0,.4-.006.6-.006S32.407,28.983,32.611,28.986Z" transform="translate(0.5 0.5)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconComptes"
}
</script>

<style scoped>

</style>